@font-face {
  font-family: 'Glamour';
  src: url('/public/fonts/Glamour.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('/public/fonts/Barlow/Barlow-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('/public/fonts/Barlow/Barlow-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100vh;
}

body {
  background-color: #191C25;
  background-image: url('/public/images/bg-full.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60px;
  font-family: 'Glamour';
  color: #F3F3F6;
  height: 100%;
  padding: 0px 24px 24px 24px;
  position: relative;
  text-align: center;
}

header {
  padding-top: 34px;
  position: relative;
  z-index: 2;
}

section {
  position: relative;
  z-index: 2;
}

#gradiente {
  background: linear-gradient(180deg, #191C25 0%, rgba(25, 28, 37, 0) 100%), linear-gradient(0deg, rgba(26, 28, 38, 0.65), rgba(26, 28, 38, 0.65));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#logo {
  height: 76px;
  width: 64px;
}

#titulo {
  font-size: 65px;
  line-height: 63px;
  margin-top: 48px;
}

#catchphrase {
  color: #C1C4D2;
  font-size: 24px;
  margin-top: 34px;
}

#catchphrase strong {
  color: #F3F3F6;
}

#email {
  font-family: 'Barlow';
  font-size: 14px;
  margin-top: 42px;
  text-align: left;
  width: 100%;
}

#email input {
  background-color: #1A1C25;
  border: 1px solid #3C4050;
  border-radius: 3px;
  color: white;
  font-size: 16px;
  outline: none;
  padding: 12px;
  margin-top: 6px;
  transition: all 0.39s ease;
  width: calc(100% - 26px);
}

#email input:focus {
  border: 1px solid #FF810D;
}

#email input::placeholder {
  color: #535975;
}

#email button {
  background-color: #FF810D;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 12px;
  padding: 16px 0;
  transition: all 0.39s ease;
  width: 100%;
}

#email button:hover {
  background-color: #EE700E;
}

#error {
  color: red;
}

.small {
  color: #C1C4D2;
  margin-top: 5px;
}

#socials {
  color: #FF810D;
  font-size: 24px;
  margin-top: 42px;
}

#socials ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#socials li {
  display: inline-block;
  margin-right: 26px;
}

#socials li:last-child {
  margin-right: 0;
}

#socials li a {
  color: inherit;
  transition: all 0.39s ease;
}

#socials li a:hover {
  color: #F3F3F6;
}

#gracias {
  margin-top: 25px;
}

@media (min-device-width: 390px) {
  #titulo {
    font-size: 64px;
  }
}

@media (min-device-width: 578px) {
  body {
    background-position: center;
  }
}

@media only screen and (min-device-width: 768px) {
  section {
    max-width: 850px;
    margin: 0 auto;
  }

  #titulo {
    font-size: 96px;
    margin-top: 120px;
  }

  #catchphrase {
    font-size: 24px;
    margin-top: 50px;
  }

  #email {
    text-align: center;
  }

  #email form div {
    display: inline-block;
  }

  #email .alineamiento {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
  }

  #email strong {
    text-align: left;
  }

  #email input {
    width: 240px;
    margin-top: 6px;
  }

  #email button {
    margin-top: 0;
    margin-left: 12px;
    padding: 12px 16px;
  }

  #socials {
    margin-top: 48px;
  }
}

@media (min-device-width: 390px) and (max-device-width: 880px) {
  #titulo {
    font-size: 75px;
  }
}
